var Webcaster = {
    uri: "ws://dj_test:djtest123@144.126.220.27/radio/8005/",
    isStreaming: true,
    bitrate: 128,
    samplerate: 44100,
    encoder: 'mp3',
    asynchronous: true,
    passThrough: false,
    metadata: {
        title: '',
        artist: ''
    },
};
let isListening = false;
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

Webcaster.start = function () {
    stream.init();
    var encoderClass;

    switch (this.encoder) {
        case 'mp3':
            encoderClass = Webcast.Encoder.Mp3;
            break;
        case 'raw':
            encoderClass = Webcast.Encoder.Raw;
    }

    let encoder = new encoderClass({
        channels: 2,
        samplerate: this.samplerate,
        bitrate: this.bitrate
    });

    if (this.samplerate !== stream.context.sampleRate) {
        encoder = new Webcast.Encoder.Resample({
            encoder: encoder,
            type: Samplerate.LINEAR,
            samplerate: stream.context.sampleRate
        });
    }
    stream.webcast.connectSocket(encoder, this.uri);
    this.isStreaming = true;
    isListening = true;
}

Webcaster.stop = function () {
    stream.webcast.close();
    this.isStreaming = false;
}

Webcaster.resumeStream = function () {
    stream.resumeContext();
}

Webcaster.pause = function () {

    console.log(navigator.mediaDevices);
    
    track.playing = !track.playing;
    this.paused = !this.paused;
    track.paused = !track.paused;
}

Webcaster.toggleRecording = function () {
    if (track.playing) {
        track.stop();
    } else {
        track.prepare();
        this.createSource(() => {
            track.playing = true;
            this.playing = true;
            this.paused = false;
        });
    }
}

Webcaster.createSource = function (cb) {
    var constraints;
    if (track.source != null) {
        track.source.disconnect(this.destination);
    }
    constraints = {
        video: false
    };
    if (this.device) {
        constraints.audio = {
            deviceId: this.device
        };
    } else {
        constraints.audio = true;
    }
    return stream.createMicrophoneSource(constraints, (source) => {
        track.source = source;
        track.source.connect(track.destination);
        return typeof cb === 'function' ? cb() : void 0;
    });
}

// Webcaster.View.Track = class Track extends Backbone.View {
//     initialize() {
//         this.model.on("change:passThrough", () => {
//             if (this.model.get("passThrough")) {
//                 return this.$(".passThrough").addClass("btn-cued").removeClass("btn-info");
//             } else {
//                 return this.$(".passThrough").addClass("btn-info").removeClass("btn-cued");
//             }
//         });
//         this.model.on("change:volumeLeft", () => {
//             // console.log(this.model.get("volumeLeft"));
//             return this.$(".volume-left").width(`${this.model.get("volumeLeft")}%`);
//         });
//         return this.model.on("change:volumeRight", () => {
//             return this.$(".volume-right").width(`${this.model.get("volumeRight")}%`);
//         });
//     }

//     onPassThrough(e) {
//         e.preventDefault();
//         return this.model.togglePassThrough();
//     }

//     onSubmit(e) {
//         return e.preventDefault();
//     }

// };
