function init_livestream() {
    const wave = new Wave();
    wave.fromElement("audio_base", "wavebase", {
        type: "bars",
        colors: ["#E71C4C", "#E71C4C", "#E71C4C", "#E71C4C", "#E71C4C"]
    });
}

function play_livestream(stream) {
    return stream.play();
}

function pause_livestream(stream) {
    vol_update_livestream(stream, 0);
    // stream.pause();
}

function vol_update_livestream(stream, vol) {
    stream.volume = vol;
}